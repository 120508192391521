<template>
    <div>
      <el-row>
        <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="4">
            <div>
                <el-card>
                    <table>
                        <tr>
                            <td> </td>
                            <td><img src="@/assets/images/jqr2.png" class="image"></td>
                            <td> </td>
                        </tr>
                        <tr style="text-align:center;vertical-align:middle;">
                            <td> </td>
                            <td><strong>财产险保单自动录入机器人</strong><br></td>
                            <td> </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style="text-align:center;vertical-align:middle;">
                            <el-button @click="handleClick_caichanxianbaodan()"  type="success" round size="mini">演示</el-button>
                            <el-button  @click="caichanxianbaodan_shuoming()" type="warning" round size="mini">说明</el-button>
                            </td>
                            <td></td>
                        </tr>
                    </table>
                </el-card>
            </div>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="4">
            <div>
                <el-card>
                    <table>
                        <tr>
                            <td> </td>
                            <td><img src="@/assets/images/jqr2.png" class="image"></td>
                            <td> </td>
                        </tr>
                        <tr style="text-align:center;vertical-align:middle;">
                            <td> </td>
                            <td><strong>合同自动同步机器人</strong><br></td>
                            <td> </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style="text-align:center;vertical-align:middle;">
                            <el-button @click="handleClick_hetongzidongtongbu()"  type="success" round size="mini">演示</el-button>
                            <el-button  @click="hetongzidongtongbu_shuoming()" type="warning" round size="mini">说明</el-button>
                            </td>
                            <td></td>
                        </tr>
                    </table>
                </el-card>
            </div>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="4">
            <div>
                <el-card>
                    <table>
                        <tr>
                            <td> </td>
                            <td><img src="@/assets/images/jqr2.png" class="image"></td>
                            <td> </td>
                        </tr>
                        <tr style="text-align:center;vertical-align:middle;">
                            <td> </td>
                            <td><strong>团体寿险保单自动录入机器人</strong><br></td>
                            <td> </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style="text-align:center;vertical-align:middle;">
                            <el-button @click="handleClick_tuantishouxian()"  type="success" round size="mini">演示</el-button>
                            <el-button  @click="tuantishouxian_shuoming()" type="warning" round size="mini">说明</el-button>
                            </td>
                            <td></td>
                        </tr>
                    </table>
                </el-card>
            </div>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="4">
            <div>
                <el-card>
                    <table>
                        <tr>
                            <td> </td>
                            <td><img src="@/assets/images/jqr2.png" class="image"></td>
                            <td> </td>
                        </tr>
                        <tr style="text-align:center;vertical-align:middle;">
                            <td> </td>
                            <td><strong>营销邮件自动发送机器人</strong><br></td>
                            <td> </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style="text-align:center;vertical-align:middle;">
                            <el-button @click="handleClick_yingxiaoyoujian()"  type="success" round size="mini">演示</el-button>
                            <el-button  @click="yingxiaoyoujian_shuoming()" type="warning" round size="mini">说明</el-button>
                            </td>
                            <td></td>
                        </tr>
                    </table>
                </el-card>
            </div>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="4">
            <div>
                <el-card>
                    <table>
                        <tr>
                            <td> </td>
                            <td><img src="@/assets/images/jqr2.png" class="image"></td>
                            <td> </td>
                        </tr>
                        <tr style="text-align:center;vertical-align:middle;">
                            <td> </td>
                            <td><strong>门户网站要闻同步机器人</strong><br></td>
                            <td> </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style="text-align:center;vertical-align:middle;">
                            <el-button @click="handleClick_menhuwangzhan()"  type="success" round size="mini">演示</el-button>
                            <el-button  @click="menhuwangzhan_shuoming()" type="warning" round size="mini">说明</el-button>
                            </td>
                            <td></td>
                        </tr>
                    </table>
                </el-card>
            </div>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="4">
            <div>
                <el-card>
                    <table>
                        <tr>
                            <td> </td>
                            <td><img src="@/assets/images/jqr2.png" class="image"></td>
                            <td> </td>
                        </tr>
                        <tr style="text-align:center;vertical-align:middle;">
                            <td> </td>
                            <td><strong>发票查验机器人</strong><br></td>
                            <td> </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style="text-align:center;vertical-align:middle;">
                            <el-button @click="handleClick_fapiaochayan()"  type="success" round size="mini">演示</el-button>
                            <el-button  @click="fapiaochayan_shuoming()" type="warning" round size="mini">说明</el-button>
                            </td>
                            <td></td>
                        </tr>
                    </table>
                </el-card>
            </div>
        </el-col>
        <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="4">
            <div>
      <el-card>
          <table>
              <tr>
                  <td> </td>
                  <td><img src="@/assets/images/jqr2.png" class="image"></td>
                  <td> </td>
              </tr>
              <tr style="text-align:center;vertical-align:middle;">
                  <td> </td>
                  <td><strong>客户对比机器人</strong><br></td>
                  <td> </td>
              </tr>
              <tr >
                  <td></td>
                  <td style="text-align:center;vertical-align:middle;">
                  <el-button  @click="handleClick_kehubidui()" type="success" round size="mini">演示</el-button>
                  <el-button  @click="kehubidui_shuoming()" type="warning" round size="mini">说明</el-button>
                  </td>
                  <td></td>
              </tr>
          </table>
      </el-card>
      </div></el-col>
      <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="4"><div >
      <el-card>
          <table >
              <tr>
                  <td> </td>
                  <td><img src="@/assets/images/jqr2.png" class="image"></td>
                  <td> </td>
              </tr>
              <tr style="text-align:center;vertical-align:middle;">
                  <td> </td>
                  <td><strong>设计器演示</strong><br></td>
                  <td> </td>
              </tr>
              <tr >
                  <td></td>
                  <td style="text-align:center;vertical-align:middle;">
                  <el-button  @click="handleClick_yanshi()" type="success" round size="mini">演示</el-button>
                  <el-button  @click="yanshi_shuoming()" type="warning" round size="mini">说明</el-button>
                  </td>
                  <td></td>
              </tr>
          </table>
      </el-card>
      </div></el-col>
    <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="4" v-if="level == '8'"><div >
      <el-card >
          <table>
              <tr>
                  <td> </td>
                  <td><img src="@/assets/images/jqr2.png" class="image"></td>
                  <td> </td>
              </tr>
              <tr style="text-align:center;vertical-align:middle;">
                  <td> </td>
                  <td><strong>收入确认凭证机器人</strong><br></td>
                  <td> </td>
              </tr>
              <tr >
                  <td></td>
                  <td style="text-align:center;vertical-align:middle;">
                  <el-button disabled @click="handleClick_shouruquerenpingzheng()" type="success" round size="mini">演示</el-button>
                  <el-button disabled type="warning" round size="mini">说明</el-button>
                  </td>
                  <td></td>
              </tr>
          </table>
      </el-card>
      </div></el-col>
    
      <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="4"><div >
      <el-card >
          <table>
              <tr>
                  <td> </td>
                  <td><img src="@/assets/images/jqr2.png" class="image"></td>
                  <td> </td>
              </tr>
              <tr style="text-align:center;vertical-align:middle;">
                  <td> </td>
                  <td><strong>银行回单打印机器人</strong><br></td>
                  <td> </td>
              </tr>
              <tr >
                  <td></td>
                  <td style="text-align:center;vertical-align:middle;">
                  <el-button disabled @click="handleClick_shouruquerenpingzheng()" type="success" round size="mini">演示</el-button>
                  <el-button disabled type="warning" round size="mini">说明</el-button>
                  </td>
                  <td></td>
              </tr>
          </table>
      </el-card>
      </div></el-col>
    <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="4"><div>
          <el-card>
          <table>
              <tr>
                  <td> </td>
                  <td><img src="@/assets/images/jqr2.png" class="image"></td>
                  <td> </td>
              </tr>
              <tr style="text-align:center;vertical-align:middle;">
                  <td> </td>
                  <td><strong>转账签名审核机器人</strong><br></td>
                  <td> </td>
              </tr>
              <tr >
                  <td></td>
                  <td style="text-align:center;vertical-align:middle;">
                  <el-button disabled @click="handleClick_shouruquerenpingzheng()" type="success" round size="mini">演示</el-button>
                  <el-button disabled type="warning" round size="mini">说明</el-button>
                  </td>
                  <td></td>
              </tr>
          </table>
      </el-card>
      </div></el-col>
    <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="4"><div>
          <el-card >
          <table>
              <tr>
                  <td> </td>
                  <td><img src="@/assets/images/jqr2.png" class="image"></td>
                  <td> </td>
              </tr>
              <tr style="text-align:center;vertical-align:middle;">
                  <td> </td>
                  <td><strong>采购发票审核机器人</strong><br></td>
                  <td> </td>
              </tr>
              <tr >
                  <td></td>
                  <td style="text-align:center;vertical-align:middle;">
                  <el-button disabled @click="handleClick_shouruquerenpingzheng()" type="success" round size="mini">演示</el-button>
                  <el-button disabled type="warning" round size="mini">说明</el-button>
                  </td>
                  <td></td>
              </tr>
          </table>
      </el-card>
      </div></el-col>
    <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="4"><div >
      <el-card>
          <table>
              <tr>
                  <td> </td>
                  <td><img src="@/assets/images/jqr2.png" class="image"></td>
                  <td> </td>
              </tr>
              <tr style="text-align:center;vertical-align:middle;">
                  <td> </td>
                  <td><strong>进项税抵扣机器人</strong><br></td>
                  <td> </td>
              </tr>
              <tr >
                  <td></td>
                  <td style="text-align:center;vertical-align:middle;">
                  <el-button disabled @click="handleClick_shouruquerenpingzheng()" type="success" round size="mini">演示</el-button>
                  <el-button disabled type="warning" round size="mini">说明</el-button>
                  </td>
                  <td></td>
              </tr>
          </table>
      </el-card>
      </div></el-col>
      </el-row>

      <el-dialog :visible.sync="editFormVisible_menhuwangzhan" width="50%" @click="closeDialog('editForm')">
        <tr>
          <h2 style="text-align:center">门户网站要闻同步机器人</h2>
            <video controls ref="videoDom1" class="video-box" autoplay style="width:100%;height:100%;">
              <source src="@/assets/video/门户网站要闻同步机器人.mp4" type="video/mp4" />
            </video>
        </tr>
      </el-dialog>
        <el-dialog :visible.sync="editFormVisible_menhuwangzhan_shuoming"
        width="50%"
        title="门户网站要闻同步机器人"
        @click="closeDialog('editForm')">
        <tr>
            <span>从国网的门户网站上比对有没有新的要闻,有的话就把新闻标题与地址复制到一个内容管理系统里，同步到长安的门户网站</span>
        </tr>
      </el-dialog>

      <el-dialog :visible.sync="editFormVisible_yingxiaoyoujian" width="50%" @click="closeDialog('editForm')">
        <tr>
          <h2 style="text-align:center">营销邮件自动发送机器人</h2>
            <video controls ref="videoDom1" class="video-box" autoplay style="width:100%;height:100%;">
              <source src="@/assets/video/营销邮件自动发送机器人.mp4" type="video/mp4" />
            </video>
        </tr>
      </el-dialog>
        <el-dialog :visible.sync="editFormVisible_yingxiaoyoujian_shuoming"
        width="50%"
        title="营销邮件自动发送机器人"
        @click="closeDialog('editForm')">
        <tr>
            <span>登录邮箱，根据规则进行自动化写信，发送邮件</span>
        </tr>
      </el-dialog>

      <el-dialog :visible.sync="editFormVisible_tuantishouxian" width="50%" @click="closeDialog('editForm')">
        <tr>
          <h2 style="text-align:center">团体寿险保单自动录入机器人</h2>
            <video controls ref="videoDom1" class="video-box" autoplay style="width:100%;height:100%;">
              <source src="@/assets/video/团体寿险保单自动录入机器人.mp4" type="video/mp4" />
            </video>
        </tr>
      </el-dialog>
        <el-dialog :visible.sync="editFormVisible_tuantishouxian_shuoming"
        width="50%"
        title="团体寿险保单自动录入机器人"
        @click="closeDialog('editForm')">
        <tr>
            <span>根据《团体寿险保单+年月日》.xlsx中的信息，向业务管理系统的团体寿险保单界面，循环录入团体寿险保单信息。</span>
        </tr>
      </el-dialog>

      <el-dialog :visible.sync="editFormVisible_hetongzidongtongbu" width="50%" @click="closeDialog('editForm')">
        <tr>
          <h2 style="text-align:center">合同自动同步机器人</h2>
            <video controls ref="videoDom1" class="video-box" autoplay style="width:100%;height:100%;">
              <source src="@/assets/video/合同自动同步机器人.mp4" type="video/mp4" />
            </video>
        </tr>
      </el-dialog>
        <el-dialog :visible.sync="editFormVisible_hetongzidongtongbu_shuoming"
        width="50%"
        title="合同自动同步机器人"
        @click="closeDialog('editForm')">
        <tr>
            <span>从门户网站进新经法系统，然后找到当年的已履毕状态的合同，读取合同信息，下载合同附件，写入到业管系统数据库，再推送给风险系统</span>
        </tr>
      </el-dialog>

      <el-dialog :visible.sync="editFormVisible_caichanxianbaodan" width="50%" @click="closeDialog('editForm')">
        <tr>
          <h2 style="text-align:center">财产险保单自动录入机器人</h2>
            <video controls ref="videoDom1" class="video-box" autoplay style="width:100%;height:100%;">
              <source src="@/assets/video/财产险保单自动录入机器人.mp4" type="video/mp4" />
            </video>
        </tr>
      </el-dialog>
        <el-dialog :visible.sync="editFormVisible_caichanxianbaodan_shuoming"
        width="50%"
        title="财产险保单自动录入机器人"
        @click="closeDialog('editForm')">
        <tr>
            <span>根据财产险保单信息自动获取流程，识别出来的信息，由用户确认信息准确性，并添加项目名称、投保人数、佣金比例（‰）、业务/项目名称、业务签报批复编号、投保人在职职工等关键信息，形成《财产险保单+年月日》.xlsx（例如《财产险保单20241231》.xlsx）</span>
        </tr>
      </el-dialog>

      <el-dialog :visible.sync="editFormVisible_fapiaochayan" width="50%" @click="closeDialog('editForm')">
        <tr>
          <h2 style="text-align:center">发票查验机器人</h2>
            <video controls ref="videoDom1" class="video-box" autoplay style="width:100%;height:100%;">
              <source src="@/assets/video/发票查验字幕版.mp4" type="video/mp4" />
            </video>
        </tr>
      </el-dialog>
        <el-dialog :visible.sync="editFormVisible_fapiaochayan_shuoming"
        width="50%"
        title="发票查验机器人"
        @click="closeDialog('editForm')">
        <tr>
            <span>对于在企业日常经营中负责财务工作的会计们来说，在繁杂琐碎的财务工作中，最让人头疼的莫过于处理报销和付款工作，而其中最耗费时间精力的就是发票问题，其中的发票验真工作技术含量低，人力成本高，而且很难做到全部查验。 面对重复性高、工作量大、规则明确的场景，RPA发票机器人是解决企业发票查验问题的最佳解决方案。</span>
        </tr>
      </el-dialog>
    <el-dialog :visible.sync="editFormVisible_kehubidui" width="50%" @click="closeDialog('editForm')">
        <tr>
      <h2 style="text-align:center">客户对比机器人</h2>
    <video controls ref="videoDom1" class="video-box" autoplay style="width:100%;height:100%;">
       <source src="@/assets/video/客户对比字幕版.mp4" type="video/mp4" />
     </video></tr>
    </el-dialog>
       <el-dialog :visible.sync="editFormVisible_kehubidui_shuoming"
        width="50%"
        title="客户比对机器人"
        @click="closeDialog('editForm')">
        <tr>
            <span>对于基础资料中的客户，获取上级单位股东信息、经营范围等信息，列出比对结果清单，同时根据系统信息，自动生成客户识别清单。</span>
        </tr>
      </el-dialog>

      <el-dialog :visible.sync="editFormVisible_yanshi" width="50%" @click="closeDialog('editForm')">
        <tr>
      <h2 style="text-align:center">设计器演示</h2>
    <video controls ref="videoDom1" class="video-box" autoplay style="width:100%;height:100%;">
       <source src="@/assets/video/演示设计器.mp4" type="video/mp4" />
     </video></tr>
     </el-dialog>
       <el-dialog :visible.sync="editFormVisible_yanshi_shuoming"
        width="50%"
        title="设计器演示"
        @click="closeDialog('editForm')">
        <tr>
            <span>国网小e设计器演示</span>
        </tr>
      </el-dialog>

    <!-- <el-dialog :visible.sync="editFormVisible" width="50%" @click="closeDialog('editForm')">
        <tr>
      <h2 style="text-align:center">银行回单打印机器人</h2>
    <video controls ref="videoDom1" class="video-box" autoplay style="width:100%;height:100%;">
       <source src="@/assets/video/hui_dan_da_yin.mp4" type="video/mp4" />
     </video></tr>
    </el-dialog> -->
    <!-- <el-dialog :visible.sync="editFormVisible_zhuanzhang" width="50%" @click="closeDialog('editForm')">
        <tr>
      <h2 style="text-align:center">转账签名审核机器人</h2>
    <video controls ref="videoDom1" class="video-box" autoplay style="width:100%;height:100%;">
       <source src="@/assets/video/zhuan_zhang_qian_ming.mp4" type="video/mp4" />
     </video></tr>
    </el-dialog>
    <el-dialog :visible.sync="editFormVisible_kehu" width="50%" @click="closeDialog('editForm')">
        <tr>
      <h2 style="text-align:center">采购发票审核机器人</h2>
    <video controls ref="videoDom1" class="video-box" autoplay style="width:100%;height:100%;">
       <source src="@/assets/video/zhuan_zhang_qian_ming.mp4" type="video/mp4" />
     </video></tr>
    </el-dialog>
    <el-dialog :visible.sync="editFormVisible_shouruquerenpingzheng" width="50%" @click="closeDialog('editForm')">
        <tr>
      <h2 style="text-align:center">收入确认凭证预与开票明细匹配机器人</h2>
    <video controls ref="videoDom1" class="video-box" autoplay style="width:100%;height:100%;">
       <source src="@/assets/video/英大侧-收入确认凭证预与开票明细匹配机器人-财务共享中心.mp4" type="video/mp4" />
     </video></tr>
    </el-dialog>
    <el-dialog :visible.sync="editFormVisible_jinxiangfapiaodikou" width="50%" @click="closeDialog('editForm')">
        <tr>
      <h2 style="text-align:center">进项发票抵扣认证数据自动核对机器人</h2>
    <video controls ref="videoDom1" class="video-box" autoplay style="width:100%;height:100%;">
       <source src="@/assets/video/进项发票抵扣认证数据自动核对机器人.mp4" type="video/mp4" />
     </video></tr>
    </el-dialog>
    <el-dialog :visible.sync="editFormVisible_jianhanghuidan" width="50%" @click="closeDialog('editForm')">
        <tr>
      <h2 style="text-align:center">建行回单打印机器人</h2>
    <video controls ref="videoDom1" class="video-box" autoplay style="width:100%;height:100%;">
       <source src="@/assets/video/建行回单打印机器人.mp4" type="video/mp4" />
     </video></tr>
    </el-dialog>
    <el-dialog :visible.sync="editFormVisible_jinshuixiangdikou" width="50%" @click="closeDialog('editForm')">
        <tr>
      <h2 style="text-align:center">进项税抵扣机器人-外网</h2>
    <video controls ref="videoDom1" class="video-box" autoplay style="width:100%;height:100%;">
       <source src="@/assets/video/进项税抵扣机器人（外网）.mp4" type="video/mp4" />
     </video></tr>
    </el-dialog>
    <el-dialog :visible.sync="editFormVisible_jinshuixiangdikou_neiwang" width="50%" @click="closeDialog('editForm')">
        <tr>
      <h2 style="text-align:center">进项税抵扣机器人-内网</h2>
    <video controls ref="videoDom1" class="video-box" autoplay style="width:100%;height:100%;">
       <source src="@/assets/video/进项税抵扣机器人-内网.mp4" type="video/mp4" />
     </video></tr>
    </el-dialog>
    <el-dialog :visible.sync="editFormVisible_nonghanghuidandayin" width="50%" @click="closeDialog('editForm')">
        <tr>
      <h2 style="text-align:center">农行回单打印机器人</h2>
    <video controls ref="videoDom1" class="video-box" autoplay style="width:100%;height:100%;">
       <source src="@/assets/video/农行回单打印机器人.mp4" type="video/mp4" />
     </video></tr>
    </el-dialog> -->
    </div>
    </template>
    
    <script scoped>
    
    export default {
      name: 'case',
      components: {
      },
      data () {
        return {
          value: localStorage.yun_user,
          typedata: [],
          robot: [],
          info: [
            {
              id: 1,
              name: '发票查验',
              src: require('../../assets/images/left_jqr.png'),
              ms: '发票查验描述'
            },
            {
              id: 2,
              name: '客户对比',
              src: require('../../assets/images/login_03.png'),
              ms: '客户对比描述'
            }
          ],
          editFormVisible_shouruquerenpingzheng: false,
          editFormVisible_kehubidui: false,
          editFormVisible_fapiaochayan: false,
          editFormVisible_fapiaochayan_shuoming: false,
          editFormVisible_kehubidui_shuoming: false,
          editFormVisible_caichanxianbaodan: false,
          editFormVisible_caichanxianbaodan_shuoming: false,
          editFormVisible_hetongzidongtongbu: false,
          editFormVisible_hetongzidongtongbu_shuoming: false,
          editFormVisible_tuantishouxian: false,
          editFormVisible_tuantishouxian_shuoming: false,
          editFormVisible_yingxiaoyoujian: false,
          editFormVisible_yingxiaoyoujian_shuoming: false,
          editFormVisible_menhuwangzhan: false,
          editFormVisible_menhuwangzhan_shuoming: false,
          editFormVisible_yanshi: false,
          editFormVisible_yanshi_shuoming: false
        }
      },
      created () {
        this.level = localStorage.getItem('role_id')
        this.gettypedata()
      },
      methods: {
        closeDialog () {
          this.editFormVisible_fapiaochayan = false
        },
        handleClick_yanshi () {
          this.editFormVisible_yanshi = true
        },
        handleClick_shouruquerenpingzheng () {
          this.editFormVisible_shouruquerenpingzheng = true
        },
        handleClick_kehubidui () {
          this.editFormVisible_kehubidui = true
        },
        handleClick_fapiaochayan () {
          this.editFormVisible_fapiaochayan = true
        },
        handleClick_caichanxianbaodan () {
          this.editFormVisible_caichanxianbaodan = true
        },
        handleClick_hetongzidongtongbu () {
          this.editFormVisible_hetongzidongtongbu = true
        },
        handleClick_tuantishouxian () {
          this.editFormVisible_tuantishouxian = true
        },
        handleClick_yingxiaoyoujian () {
          this.editFormVisible_yingxiaoyoujian = true
        },
        handleClick_menhuwangzhan () {
          this.editFormVisible_menhuwangzhan = true
        },
        fapiaochayan_shuoming () {
          this.editFormVisible_fapiaochayan_shuoming = true
        },
        caichanxianbaodan_shuoming () {
          this.editFormVisible_caichanxianbaodan_shuoming = true
        },
        hetongzidongtongbu_shuoming () {
          this.editFormVisible_hetongzidongtongbu_shuoming = true
        },
        tuantishouxian_shuoming () {
          this.editFormVisible_tuantishouxian_shuoming = true
        },
        yingxiaoyoujian_shuoming () {
          this.editFormVisible_yingxiaoyoujian_shuoming = true
        },
        menhuwangzhan_shuoming () {
          this.editFormVisible_menhuwangzhan_shuoming = true
        },
        kehubidui_shuoming () {
          this.editFormVisible_kehubidui_shuoming = true
        },
        yanshi_shuoming() {
          this.editFormVisible_yanshi_shuoming = true
        },
        gettypedata () {
          this.axios.post('/login/gettype/', {
            employeeid: localStorage.getItem('user_id')
          }).then((res) => {
            this.typedata = res.data.data
          }).catch((error) => {
            console.log(error)
          })
        },
        robotdata (rtypeid) {
          this.axios.post('/login/getrobotdata/', {
            employeeid: localStorage.getItem('user_id'),
            typeid: rtypeid
          }).then((res) => {
            this.robot = res.data.data
            console.log(this.robot)
          }).catch((error) => {
            console.log(error)
          })
        }
      }
    }
    
    </script>
    
    <style>
      /* .el-row {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
      } */
      .el-col {
        border-radius: 4px;
      }
      /* .bg-purple-dark {
        background: #99a9bf;
      } */
      .bg-purple {
        background: #d3dce6;
      }
      .bg-purple-light {
        background: #e5e9f2;
      }
      .grid-content {
        border-radius: 4px;
        min-height: 36px;
      }
      .row-bg {
        padding: 10px 0;
        background-color: #ffffff;
      }
      .new_warp{
        padding-top: 0;
      }
      .box-card {
    width: 70%;
    text-align: center;
    border: 10px;
    padding: 10px 80px 10px 0px;
}

/* 小屏幕（例如手机设备） */
@media (max-width: 600px) {
    .box-card {
        padding: 10px 20px; /* 在小屏幕上减少padding */
    }
}

/* 中等屏幕（例如平板设备） */
@media (min-width: 601px) and (max-width: 1024px) {
    .box-card {
        padding: 10px 40px; /* 在中等屏幕上适度调整padding */
    }
}

/* 大屏幕 */
@media (min-width: 1025px) {
    .box-card {
        padding: 10px 80px 10px 0px; /* 在大屏幕上保持原样 */
    }
}
    </style>
    